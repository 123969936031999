/* KezaAdminDashboard.css */
.keza-admin-dashboard {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.keza-sidebar {
  width: 250px;
  background-color: #f0f2f5;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.keza-sidebar-header {
  margin-bottom: 20px;
}

.keza-sidebar-header h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.keza-sidebar-header p {
  margin: 5px 0 0;
  font-size: 12px;
  color: #666;
}

.keza-sidebar-menu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.keza-sidebar-menu button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
}

.keza-sidebar-menu button:hover {
  background-color: #0056b3;
}

.keza-chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.keza-chat-header {
  background-color: #007bff;
  color: white;
  padding: 20px;
}

.keza-chat-header h1 {
  margin: 0;
  font-size: 24px;
}

.keza-chat-messages {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.keza-message {
  margin-bottom: 15px;
}

.keza-message-bubble {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
}

.keza-message.bot .keza-message-bubble {
  background-color: #f0f0f0;
}

.keza-message.user .keza-message-bubble {
  background-color: #007bff;
  color: white;
  float: right;
}

.keza-chat-input {
  display: flex;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
}

.keza-chat-input input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
}

.keza-chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keza-chat-input button:hover {
  background-color: #0056b3;
}