/* KezaHealth Mobile Chatbot CSS */

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #f0f2f5;
}

.keza-health-chatbot {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 414px;
  min-width: 300px;
  margin: 0 auto;
  background-color: white;
}

.chatbot-menu {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.chatbot-menu button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background: none;
  border: none;
  text-align: left;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.chatbot-menu button:hover {
  background-color: #f5f5f5;
}

.chatbot-menu button svg {
  margin-right: 10px;
  vertical-align: middle;
}


.chatbot-header {
  background-color: #4a90e2;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #3a7fcf;
}

.chatbot-header h1 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.menu-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  background-color: #ffffff;
}

.message {
  max-width: 80%;
  margin-bottom: 15px;
  clear: both;
}

.message.bot {
  float: left;
}

.message.user {
  float: right;
}

.message-bubble {
  padding: 10px 15px;
  border-radius: 18px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.4;
}

.message.bot .message-bubble {
  background-color: #f0f0f0;
  color: #000000;
}

.message.user .message-bubble {
  background-color: #4a90e2;
  color: white;
}

.chat-input {
  display: flex;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #e0e0e0;
}

.chat-input input {
  flex: 1;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  margin-right: 10px;
}

.chat-input button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chat-input button svg {
  width: 20px;
  height: 20px;
}

.sidebar {
  display: none; /* Hide sidebar for mobile view */
}

@media (min-width: 415px) {
  body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .keza-health-chatbot {
    height: 90vh;
    max-height: 800px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}