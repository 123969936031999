/* NurseDashboard.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
  background-color: #f0f0f0;
}

.keza-nurse-dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.keza-dashboard-header {
  background-color: #0084ff;
  color: white;
  padding: 20px;
  text-align: left;
  border-bottom: 5px solid #6200ee;
}

.keza-dashboard-title {
  margin: 0;
  font-size: 24px;
}

.dashboard-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  width: 250px;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e0e0e0;
}

.sidebar h2 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
}

.sidebar p {
  margin-bottom: 20px;
  color: #666;
  font-size: 14px;
}

.sidebar button {
  background-color: #0084ff;
  color: white;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 25px;
  transition: background-color 0.3s;
}

.sidebar button:hover {
  background-color: #0066cc;
}

.sidebar button svg {
  margin-right: 10px;
}

.chat-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px;
}

.message {
  max-width: 80%;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.4;
}

.message.bot {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: #333;
}

.message.user {
  align-self: flex-end;
  background-color: #0084ff;
  color: white;
}

.chat-input {
  display: flex;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.chat-input input {
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-right: 10px;
  font-size: 14px;
}

.chat-input button {
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-input button:hover {
  background-color: #0066cc;
}

/* New styles for the updated layout */
.keza-dashboard-content {
  display: flex;
  flex: 1;
}

.keza-sidebar {
  width: 250px;
  padding: 20px;
  background-color: #fff;
  border-right: 1px solid #e0e0e0;
}

.keza-sidebar h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.keza-sidebar p {
  margin-bottom: 20px;
}

.keza-sidebar button {
  width: 100%;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.keza-chat-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.keza-chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
}

.keza-message {
  max-width: 80%;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.keza-message.bot {
  align-self: flex-start;
  background-color: #e0e0e0;
}

.keza-message.user {
  align-self: flex-end;
  background-color: #0084ff;
  color: white;
}

.keza-chat-input {
  display: flex;
}

.keza-chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-right: 10px;
}

.keza-chat-input button {
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}