/* PatientDashboard.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
  background-color: #f0f0f0;
}

.keza-health-chatbot {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chatbot-header {
  background-color: #0084ff;
  color: white;
  padding: 20px;
  text-align: center;
  border-bottom: 5px solid #6200ee;
}

.chatbot-header h1 {
  margin: 0;
  font-size: 24px;
}

.dashboard-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  width: 250px;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e0e0e0;
}

.sidebar h2 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
}

.sidebar p {
  margin-bottom: 20px;
  color: #666;
  font-size: 14px;
}

.sidebar button {
  background-color: #0084ff;
  color: white;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 25px;
  transition: background-color 0.3s;
}

.sidebar button:hover {
  background-color: #0066cc;
}

.sidebar button svg {
  margin-right: 10px;
}

.chat-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px;
}

.message {
  max-width: 80%;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.4;
}

.message.bot {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: #333;
}

.message.user {
  align-self: flex-end;
  background-color: #0084ff;
  color: white;
}

.chat-input {
  display: flex;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.chat-input input {
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-right: 10px;
  font-size: 14px;
}

.chat-input button {
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-input button:hover {
  background-color: #0066cc;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 90%;
}

.modal-content h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.modal-content button {
  background-color: #0084ff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.modal-content button:hover {
  background-color: #0066cc;
}

@media (max-width: 768px) {
  .dashboard-content {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .chat-area {
    height: 60vh;
  }
}