* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  height: 100%;
  font-family: Arial, sans-serif;
}

.landing-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
}

.navbar {
  background-color: #4285F4;
  color: white;
  padding: 1rem;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-link {
  margin: 0 0.5rem;
  color: white;
  text-decoration: none;
}

.content-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
}

.main-content {
  width: 100%;
  max-width: 1400px;
}

.main-title {
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
}

.main-subtitle {
  font-size: clamp(1rem, 3vw, 1.25rem);
  text-align: center;
  margin-bottom: 3rem;
  color: #666;
}

.user-sections {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.user-section {
  flex: 1 1 300px;
  max-width: calc(25% - 0.75rem);
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.user-section-icon {
  width: 2rem;
  height: 2rem;
  color: #4285F4;
  margin-bottom: 1rem;
}

.user-section-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.user-section-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.user-section-buttons {
  display: flex;
  flex-direction: column;
}

.button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.5rem;
  transition: background-color 0.3s ease;
}

.button-primary {
  background-color: #4285F4;
  color: white;
}

.button-primary:hover {
  background-color: #3367D6;
}

.button-secondary {
  background-color: #34A853;
  color: white;
}

.button-secondary:hover {
  background-color: #2E8B57;
}

.security-check {
  max-width: 600px;
  width: 90%;
  margin: 50px auto;
  padding: 40px;
  border: 2px solid #007bff;
  border-radius: 10px;
  text-align: center;
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.security-check h2 {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 20px;
}

.security-check p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.security-check .math-question {
  font-size: 2rem;
  font-weight: bold;
  color: #28a745;
  margin: 20px 0;
}

.security-check input {
  margin: 15px 0;
  padding: 12px;
  width: 100%;
  font-size: 1.2rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.security-check button {
  margin-top: 20px;
  padding: 12px 24px;
  font-size: 1.2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.security-check button:hover {
  background-color: #0056b3;
}

.security-check .message {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #dc3545;
}

.security-icon {
  width: 64px;
  height: 64px;
  color: #007bff;
  margin-bottom: 20px;
}

.footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
}

@media (max-width: 1200px) {
  .user-section {
    max-width: calc(50% - 0.5rem);
  }
}

@media (max-width: 768px) {
  .user-section {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .navbar .container {
    flex-direction: column;
    gap: 1rem;
  }
}