/* MobileNurseChatbot.css */

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    font-family: Arial, sans-serif;
  }
  
  .mobile-nurse-chatbot {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 400px;
    max-width: 414px;
    height: 100vh;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .chatbot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #0084ff;
    color: white;
  }
  
  .chatbot-header h1 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .chatbot-header button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .menu {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .menu button {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    text-align: left;
    background: none;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .menu button:last-child {
    border-bottom: none;
  }
  
  .menu button:hover {
    background-color: #0084ff;
    color: white;
  }
  
  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
  }
  
  .message {
    max-width: 70%;
    margin-bottom: 1rem;
  }
  
  .message.user {
    margin-left: auto;
  }
  
  .message-bubble {
    padding: 0.75rem;
    border-radius: 1rem;
  }
  
  .message.bot .message-bubble {
    background-color: #f0f0f0;
    color: #000;
  }
  
  .message.user .message-bubble {
    background-color: #0084ff;
    color: white;
  }
  
  .chat-input {
    display: flex;
    padding: 1rem;
    background-color: white;
    border-top: 1px solid #e0e0e0;
  }
  
  .chat-input input {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
  
  .chat-input input:focus {
    outline: none;
    border-color: #0084ff;
  }
  
  .chat-input button {
    background-color: #0084ff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    cursor: pointer;
  }
  
  @media (min-height: 900px) {
    .mobile-nurse-chatbot {
      height: 900px;
      margin: auto;
    }
  }