/* Add this to the end of your existing CSS file */

.keza-loading {
  display: flex;
  justify-content: center;
}

.keza-dot {
  background-color: #333;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  animation: keza-bounce 1.4s infinite ease-in-out both;
}

.keza-dot:nth-child(1) {
  animation-delay: -0.32s;
}

.keza-dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes keza-bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}